import { Limits } from './interfaces/limits';
import { SendMediaOptions } from './conversation';
import { UnsentMessage } from './unsent-message';

/**
 * Message builder. Allows the message to be built and sent via method chaining.
 *
 * Example:
 *
 * ```ts
 * await testConversation.prepareMessage()
 *   .setBody('Hello!')
 *   .setAttributes({foo: 'bar'})
 *   .addMedia(media1)
 *   .addMedia(media2)
 *   .build()
 *   .send();
 * ```
 */
class MessageBuilder {
  private readonly message: UnsentMessage;

  /**
   * @internal
   */
  constructor(private readonly limits: Limits, messagesEntity: any) {
    this.message = new UnsentMessage(messagesEntity);
  }

  /**
   * Sets the message body.
   * @param text Contents of the body.
   */
  setBody(text: string): MessageBuilder {
    this.message.text = text;
    return this;
  }

  /**
   * Sets the message subject.
   * @param subject Contents of the subject.
   */
  setSubject(subject: string): MessageBuilder {
    this.message.emailOptions.subject = subject;
    return this;
  }

  /**
   * Sets the message attributes.
   * @param attributes Message attributes.
   */
  setAttributes(attributes: any): MessageBuilder {
    this.message.attributes = attributes;
    return this;
  }

  /**
   * Adds media to the message.
   * @param payload Media to add.
   */
  addMedia(payload: FormData | SendMediaOptions): MessageBuilder {
    this.message.mediaContent.push(['media', payload]);
    return this;
  }

  /**
   * Builds the message, making it ready to be sent.
   */
  build(): UnsentMessage {
    if (this.message.mediaContent.length > this.limits.mediaAttachmentsCountLimit) {
      throw new Error(`Too many media attachments in the message (${this.message.mediaContent.length} > ${this.limits.mediaAttachmentsCountLimit})`);
    }

    // @todo we don't know the sizes of the attachments in FormData

    return this.message;
  }

  private getPayloadContentType(payload: FormData | SendMediaOptions): string {
    if (typeof FormData !== 'undefined' && (payload instanceof FormData)) {
      return payload.get('Content-Type') as string;
    }
    return (payload as SendMediaOptions).contentType;
  }
}

export { MessageBuilder };
