import { EventEmitter } from 'events';
import { Logger } from '../logger';

import {
  Message,
  MessageUpdatedEventArgs,
  MessageUpdateReason
} from '../message';
import {
  Conversation,
  SendEmailOptions,
  SendMediaOptions
} from '../conversation';
import { UnsentMessage } from '../unsent-message';

import { SyncList, SyncClient } from 'twilio-sync';
import { SyncPaginator } from '../sync-paginator';

import { McsClient, McsMedia } from '@twilio/mcs-client';
import { Network } from '../services/network';
import { Configuration } from '../configuration';
import { CommandExecutor } from '../command-executor';
import { SendMessageRequest } from '../interfaces/commands/send-message';
import { MessageResponse } from '../interfaces/commands/message-response';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type MessagesEvents = {
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
};

const log = Logger.scope('Messages');

export interface MessagesServices {
  mcsClient: McsClient;
  network: Network;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

/**
 * Represents the collection of messages in a conversation
 */
class Messages extends ReplayEventEmitter<MessagesEvents> {
  public readonly conversation: Conversation;
  private readonly configuration: Configuration;
  private readonly services: MessagesServices;
  private readonly messagesByIndex: Map<number, Message>;
  private messagesListPromise: Promise<SyncList>;

  public constructor(
    conversation: Conversation,
    configuration: Configuration,
    services: MessagesServices
  ) {
    super();

    this.conversation = conversation;
    this.configuration = configuration;
    this.services = services;

    this.messagesByIndex = new Map();
    this.messagesListPromise = null;
  }

  /**
   * Subscribe to the Messages Event Stream
   * @param name - The name of Sync object for the Messages resource.
   */
  public async subscribe(name: string) {
    if (this.messagesListPromise) {
      return this.messagesListPromise;
    }

    this.messagesListPromise = this.services.syncClient.list({
      id: name,
      mode: 'open_existing',
    });

    try {
      const list = await this.messagesListPromise;

      list.on('itemAdded', (args) => {
        log.debug(`${this.conversation.sid} itemAdded: ${args.item.index}`);

        const links = {
          self: `${this.conversation.links.messages}/${args.item.data.sid}`,
          conversation: this.conversation.links.self,
          messages_receipts: `${this.conversation.links.messages}/${args.item.data.sid}/Receipts`,
        };
        const message = new Message(
          args.item.index,
          args.item.data,
          this.conversation,
          links,
          this.configuration,
          this.services
        );

        if (this.messagesByIndex.has(message.index)) {
          log.debug(
            'Message arrived, but is already known and ignored',
            this.conversation.sid,
            message.index
          );
          return;
        }

        this.messagesByIndex.set(message.index, message);

        message.on('updated', (args: MessageUpdatedEventArgs) =>
          this.emit('messageUpdated', args)
        );

        this.emit('messageAdded', message);
      });

      list.on('itemRemoved', (args) => {
        log.debug(`#{this.conversation.sid} itemRemoved: ${args.index}`);

        const index = args.index;

        if (this.messagesByIndex.has(index)) {
          let message = this.messagesByIndex.get(index);
          this.messagesByIndex.delete(message.index);
          message.removeAllListeners('updated');
          this.emit('messageRemoved', message);
        }
      });

      list.on('itemUpdated', (args) => {
        log.debug(`${this.conversation.sid} itemUpdated: ${args.item.index}`);

        const message = this.messagesByIndex.get(args.item.index);

        if (message) {
          message._update(args.item.data);
        }
      });

      return list;
    } catch (err) {
      this.messagesListPromise = null;

      if (this.services.syncClient.connectionState !== 'disconnected') {
        log.error(
          'Failed to get messages object for conversation',
          this.conversation.sid,
          err
        );
      }

      log.debug(
        'ERROR: Failed to get messages object for conversation',
        this.conversation.sid,
        err
      );

      throw err;
    }
  }

  public async unsubscribe() {
    if (!this.messagesListPromise) {
      return;
    }

    const entity = await this.messagesListPromise;
    entity.close();
    this.messagesListPromise = null;
  }

  /**
   * Send Message to the conversation, message could include both text and multiple media attachments.
   * @param message Message to post
   * @returns Returns promise which can fail
   */
  public async sendV2(message: UnsentMessage) {
    log.debug(
      'Sending message V2',
      message.mediaContent,
      message.attributes,
      message.emailOptions
    );

    const media: McsMedia[] = [];

    for (const [category, mediaContent] of message.mediaContent) {
      log.debug(
        `Adding media to a message as ${
          mediaContent instanceof FormData ? 'FormData' : 'SendMediaOptions'
        }`,
        mediaContent
      );

      media.push(
        mediaContent instanceof FormData
          ? await this.services.mcsClient.postFormData(mediaContent, category)
          : await this.services.mcsClient.post(
            mediaContent.contentType,
            mediaContent.media,
            category,
            mediaContent.filename
          )
      );
    }

    return await this.services.commandExecutor.mutateResource<
      SendMessageRequest,
      MessageResponse
    >('post', this.conversation.links.messages, {
      body: message.text,
      subject: message.emailOptions?.subject,
      media_sids: media.map((m) => m.sid),
      attributes:
        typeof message.attributes !== 'undefined'
          ? JSON.stringify(message.attributes)
          : undefined,
    });
  }

  /**
   * Send Message to the conversation
   * @param message Message to post
   * @param attributes Message attributes
   * @param emailOptions Options that modify E-mail integration behaviors.
   * @returns Returns promise which can fail
   */
  public async send(
    message: string | null,
    attributes: any = {},
    emailOptions?: SendEmailOptions
  ): Promise<MessageResponse> {
    log.debug('Sending text message', message, attributes, emailOptions);

    return await this.services.commandExecutor.mutateResource<
      SendMessageRequest,
      MessageResponse
      >('post', this.conversation.links.messages, {
      body: message ?? '',
      attributes:
        typeof attributes !== 'undefined'
          ? JSON.stringify(attributes)
          : undefined,
      subject: emailOptions?.subject,
    });
  }

  /**
   * Send Media Message to the conversation
   * @param mediaContent Media content to post
   * @param attributes Message attributes
   * @param emailOptions Email options
   * @returns Returns promise which can fail
   */
  public async sendMedia(
    mediaContent: FormData | SendMediaOptions,
    attributes: any = {},
    emailOptions?: SendEmailOptions
  ) {
    log.debug('Sending media message', mediaContent, attributes, emailOptions);
    log.debug(
      `Sending media message as ${
        mediaContent instanceof FormData ? 'FormData' : 'SendMediaOptions'
      }`,
      mediaContent,
      attributes
    );

    const media: McsMedia =
      mediaContent instanceof FormData
        ? await this.services.mcsClient.postFormData(mediaContent)
        : await this.services.mcsClient.post(
          mediaContent.contentType,
          mediaContent.media,
          'media',
          mediaContent.filename
        );

    // emailOptions are currently ignored for media messages.
    return await this.services.commandExecutor.mutateResource<
      SendMessageRequest,
      MessageResponse
      >('post', this.conversation.links.messages, {
      media_sids: [media.sid],
      attributes:
        typeof attributes !== 'undefined'
          ? JSON.stringify(attributes)
          : undefined,
    });
  }

  /**
   * Returns messages from conversation using paginator interface
   * @param pageSize Number of messages to return in single chunk. By default it's 30.
   * @param anchor Most early message id which is already known, or 'end' by default
   * @param direction Pagination order 'backwards' or 'forward', 'forward' by default
   * @returns Last page of messages by default
   */
  public async getMessages(
    pageSize: number,
    anchor: number | 'end',
    direction: 'forward' | 'backwards' = 'backwards'
  ): Promise<SyncPaginator<Message>> {
    return this._getMessages(pageSize, anchor, direction);
  }

  private _wrapPaginator(order, page, op) {
    // Due to an inconsistency between Sync and Chat conventions, next and
    // previous pages should be swapped.
    const shouldReverse = order === 'desc';

    const nextPage = () =>
      page.nextPage().then((page) => this._wrapPaginator(order, page, op));
    const previousPage = () =>
      page.prevPage().then((page) => this._wrapPaginator(order, page, op));

    return op(page.items).then((items) => ({
      items: items.sort((x, y) => {
        return x.index - y.index;
      }),
      hasPrevPage: shouldReverse ? page.hasNextPage : page.hasPrevPage,
      hasNextPage: shouldReverse ? page.hasPrevPage : page.hasNextPage,
      prevPage: shouldReverse ? nextPage : previousPage,
      nextPage: shouldReverse ? previousPage : nextPage,
    }));
  }

  private _upsertMessage(index: number, value: any) {
    const cachedMessage = this.messagesByIndex.get(index);

    if (cachedMessage) {
      return cachedMessage;
    }

    const links = {
      self: `${this.conversation.links.messages}/${value.sid}`,
      conversation: this.conversation.links.self,
      messages_receipts: `${this.conversation.links.messages}/${value.sid}/Receipts`,
    };
    const message = new Message(
      index,
      value,
      this.conversation,
      links,
      this.configuration,
      this.services
    );

    this.messagesByIndex.set(message.index, message);

    message.on('updated', (args: MessageUpdatedEventArgs) =>
      this.emit('messageUpdated', args)
    );

    return message;
  }

  /**
   * Returns last messages from conversation
   * @param {Number} [pageSize] Number of messages to return in single chunk. By default it's 30.
   * @param {String} [anchor] Most early message id which is already known, or 'end' by default
   * @param {String} [direction] Pagination order 'backwards' or 'forward', or 'forward' by default
   * @returns {Promise<SyncPaginator<Message>>} last page of messages by default
   * @private
   */
  private async _getMessages(
    pageSize = 30,
    anchor: number | 'end' = 'end',
    direction: 'forward' | 'backwards' = 'forward'
  ): Promise<SyncPaginator<Message>> {
    const order = direction === 'backwards' ? 'desc' : 'asc';
    const list = await this.messagesListPromise;
    const page = await list.getItems({
      from: anchor !== 'end' ? anchor : void 0,
      pageSize,
      order,
      limit: pageSize, // @todo Limit equals pageSize by default in Sync. This is probably not ideal.
    });

    return await this._wrapPaginator(order, page, (items) =>
      Promise.all(
        items.map((item) => this._upsertMessage(item.index, item.data))
      )
    );
  }
}

export { Messages };
